/* BookmakerDetails.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  background: #f4f4f4; /* Lys grå baggrund */
  color: #333; /* Mørk tekstfarve */
  min-height: 100vh; /* Fyld hele højden af skærmen */
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 800px;
  width: 100%;
  text-align: center;
  padding: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.bookmaker-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  animation: fade-in 1s ease-in-out;
}

.bookmaker-name {
  font-size: 2.5em;
  margin: 20px 0;
  animation: slide-in 1s ease-out;
}

.bookmaker-bonus {
  font-size: 1.5em;
  color: #ff4f4f;
  margin: 10px 0;
}

.bookmaker-review {
  margin-top: 20px;
  text-align: left;
  font-size: 1.2em;
  line-height: 1.6;
}

.rating {
  margin-top: 20px;
  font-size: 2em;
  color: #ffd700; /* Guld farve til stjerner */
  animation: bounce 2s infinite;
}

.bookmaker-terms {
  margin-top: 20px;
  font-size: 1em;
  color: #666;
}

.affiliate-link {
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #ff4f4f; /* Rød knap */
  border-radius: 10px;
  text-decoration: none;
  animation: shake 1s infinite;
}

@keyframes pop-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

.not-found {
  color: #ff4f4f;
  font-size: 2em;
  font-weight: bold;
}
